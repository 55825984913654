@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  background-image: 
  radial-gradient(circle at 20% 30%, #03142f, transparent 10%),
  radial-gradient(circle at 60% 70%, #030a22, transparent 12%),
  radial-gradient(circle at 40% 50%, #03142f, transparent 8%),
  radial-gradient(circle at 80% 40%, #030a22, transparent 10%),
  radial-gradient(circle at 30% 80%, #03142f, transparent 9%),
  linear-gradient(to right, #03142f, #030a22, #03142f);


  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-custom {
  box-shadow: 0 1px 20px rgba(255, 255, 255, 0.05);
}